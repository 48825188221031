import { useEffect, useMemo, useRef, useState, type FC } from "react";
import LazyLoad from "react-lazy-load";
import { personImages } from "~/components/AboutUs/Team/fluxonTeamList";
import ImageCircle from "~/components/home/Map/ImageCircle.tsx";
import { desktopImagePositions, imageNames, quotes } from "~/components/home/Map/mapDetails.ts";
import { NavButton } from "~/components/UI/NavButton";

import { disableMotion } from "~/config";
import { PeopleSlider } from "./PeopleSlider";

const PeopleImages: FC = () => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const memoizedQuotes = useMemo(() => quotes, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  useEffect(() => {
    if (disableMotion) return;

    let index = 0;
    intervalRef.current = setInterval(() => {
      if (index >= memoizedQuotes.length) {
        index = 0;
      }
      setQuoteIndex(index);
      index++;
    }, 7000);

    return () => clearInterval(intervalRef.current!);
  }, [memoizedQuotes, disableMotion]);

  const handlePrev = () => {
    clearInterval(intervalRef.current!);
    setQuoteIndex((prevIndex) => (prevIndex === 0 ? memoizedQuotes.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    clearInterval(intervalRef.current!);
    setQuoteIndex((prevIndex) => (prevIndex === memoizedQuotes.length - 1 ? 0 : prevIndex + 1));
  };

  const renderContent = () => {
    if (isMobile) {
      return <PeopleSlider />;
    }
    return quotes.map((quote, index) => {
      return (
        <div
          className={`quote-content stroke-wrapper ${quoteIndex === index ? "active" : ""}`}
          key={quote.author}
          style={{ position: "absolute", left: quote.position.left, top: quote.position.top }}
        >
          <div className={`quote-block ${quoteIndex === index ? "active" : ""}`}>
            <div className="quote-image">
              <img src={personImages[quote.imageName].src} alt={quote.author} />
            </div>
            <p className="heading-4 pb-1">{quote.author}</p>
            <p className="text-overline text-dark-80">{quote.role}</p>
            <p className="heading-6 mt-5">{quote.text}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <LazyLoad offset={100}>
        <div
          className={`${isMobile ? "px-5" : ""} flex-col max-md:static max-mobile-header:flex-col-reverse ${disableMotion ? "disable-motion" : ""}`}
        >
          {renderContent()}
          <svg viewBox="0 0 845.2 458" className="people-location" style={{ position: "absolute" }}>
            <defs>
              {imageNames.map((name, i) => {
                const isThird = i % 3 === 0;
                return (
                  <pattern key={`${name}-${i}`} id={`person-${i}`} x="0" y="0" height="1" width="1">
                    <image
                      x="0"
                      y="0"
                      width={
                        imageNames[i] === quotes[quoteIndex].imageName
                          ? "36"
                          : isThird
                            ? "18"
                            : "22"
                      }
                      height={
                        imageNames[i] === quotes[quoteIndex].imageName
                          ? "36"
                          : isThird
                            ? "18"
                            : "22"
                      }
                      xlinkHref={personImages[name].src}
                      style={{ transition: "all .6s ease-in-out" }}
                    />
                  </pattern>
                );
              })}
            </defs>
            {desktopImagePositions.map(([x, y], i) => {
              const isThird = i % 3 === 0;
              return (
                <ImageCircle
                  key={`${x}-${i}`}
                  cx={x}
                  cy={y}
                  r={
                    isMobile
                      ? imageNames[i] === quotes[quoteIndex].imageName
                        ? "8"
                        : "0"
                      : imageNames[i] === quotes[quoteIndex].imageName
                        ? "18"
                        : isThird
                          ? "8"
                          : "10"
                  }
                  fill={isMobile ? "#0D3A9A" : `url(#person-${i})`}
                  animated={imageNames[i] === quotes[quoteIndex].imageName}
                  index={i <= 9 ? 10 + i : i > 9 && i < 15 ? i : i - 9}
                />
              );
            })}
          </svg>
        </div>
      </LazyLoad>

      <div className="navigation-buttons absolute inset-x-0 bottom-[10rem] z-[20] mt-4 flex justify-center gap-10 text-center max-md:hidden">
        <NavButton direction="prev" onClick={handlePrev} />
        <NavButton direction="next" onClick={handleNext} />
      </div>
    </>
  );
};

export default PeopleImages;
